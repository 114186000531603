import extend from "./functions/extend";
import getCheckText from "./functions/translations";
import initForm from "./functions/initform";
import { validate, getbadwords } from "./functions/validate";
import {getBS} from "./functions/helpers";
import { isPromise, getUserData } from "./functions/userinfo";

// Console Logging only if settings.debug is set...
console.debug = (msg, settings) => {
	if (settings.debug) console.log(msg);
};

var stats = {
	author: "CS_AUTHOR",
	version: {
		major: CS_VER_1,
		minor: CS_VER_2,
		patch: CS_VER_3
	}
};

stats.versionStr = Object.values(stats.version).join('.');

var userinfo;

function captcha(el, options) {
	var forms = typeof el !== "object" ? document.querySelectorAll(el) : el;
	
	var settings = extend(true, {
		debug: false,
		userinfo: false,
		bootstrap: getBS(),
		type: '',
		action: '',
		button: '.btn-submit',
		types: {
			formmail: {
				recipients: 77,
				subject: `Crema Captcha ${stats.versionStr} Test`,
				action: "?modal=error"
			},
			craft2: {},
			generic: {}
		},
		filters: {
			checkbox: true,
			honeypot: true,
			russian: true,
			required: true,
			profanity: true,
			ipblock: true
		},
		// false or CSV string of filters to disable:
		disable: false,
		config: {
			checkbox: {
				parent: '.captcha',
				selector: '.chk-human',
				text: getCheckText(),
				weight: 1
			},
			honeypot: {
				selector: '.honeypot',
				weight: 1
			},
			russian: {
				weight: 1
			},
			profanity: {
				max: 5,
				max_pct: 50,
				badwords: getbadwords(VAR_BADWORDS),
				weight: 1
			}
		}
	}, options);
	
	// Disable Filter Helper
	if (settings.disable) {
		var filters = settings.disable.split(',');
		
		for (var i = 0; i < filters.length; i++) {
			var filter = filters[i];
			
			if (settings.debug) console.log(`Disabling: ${filter}...`);
			settings.filters[filter] = false;
		}
	}
	
	// Return settings if no forms exist
	if (! forms.length) return settings;
	
	if (settings.userinfo && ! isPromise(userinfo)) {
		userinfo = getUserData("/location-data.php");
	}
	
	if (options && (options.valid || options.invalid)) {
		var callback = true;
	}
	
	forms.forEach(form => {
		// 1. Insert default fields and/or optional checkbox captcha.
		initForm(form, settings, userinfo);
		
		// 2. Define variables AFTER initializing the form.
		var inputs = form.querySelectorAll('input, select, textarea');
		
		// 3. Check validation on blur and change.
		inputs.forEach(input => input.addEventListener("change", () => {
			validate({
				form: form,
				inputs: inputs,
				options: options,
				settings: settings,
				callback: callback
			});
		}));
	});
}

// make accessible globally
window.captcha = captcha;
window.captcha.version = stats.versionStr;
window.captcha.author = stats.author;
